import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Background, Container, CopyButton, TaskColumn, TaskText } from "./styled";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Task = () => {
    const { taskId } = useParams();
    const [column, setColumn] = useState<string[]>([]);
    const [value, setValue] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://api.tasktrack.teracock.com/task?id=${taskId}`)
        .then(response => {
            if (Object.keys(response.data.task).length === 0) {
                    navigate("/");
            } else {
                let column: string[] = [];
                let value: string[] = [];
                for (let elem in response.data.task) {
                    column.push(elem + ':');
                    value.push(response.data.task[elem]);
                }
                setColumn(column);
                setValue(value);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }, [taskId]);

    const copyTextToClipboard = (index: number) => {
        navigator.clipboard.writeText(value[index]);
    };

    return (
        <Background>
            <Container>
            {value.length !== 0 && column.length !== 0 && (
                column.map((item, index) => (
                    <>
                        <TaskColumn key={index}>
                            {item}
                        </TaskColumn>
                        <TaskText key={index}>
                            {value[index]}
                        </TaskText>
                        <CopyButton onClick={() => copyTextToClipboard(index)}>Copy</CopyButton>
                    </>
                ))
            )}
            </Container>
        </Background>
    );
};

export default Task;