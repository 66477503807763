import { Application } from './styled';
import ContentProvider from '../ContentProvider';
import '../../index.css';

function App() {
  return (
  <Application>
      <ContentProvider/>
  </Application>
  );
}

export default App;