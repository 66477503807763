import { useState, useRef } from "react";
import { Background, Container, ButtonCreate, ButtonShow, WrapperText, ErrorText, SuccessText, ListWrapper, ListElemWrapper, ListElemText } from "./styled";
import axios from "axios";
import TaskSvg from '../../images/task.svg';
import useOnClickOutside from 'use-onclickoutside';
import { Link } from "react-router-dom";

const Main = () => {

    const listRef = useRef(null);
    const [isError, setIsError] = useState(false);
    const [isFileError, setIsFileError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isOpenList, setIsOpenList] = useState(false);
    const [listData, setListData] = useState<any>(null);

    const handleFileUpload = () => {
        setIsError(false);
        setIsFileError(false);
        setIsSuccess(false);
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            const target = e.target as HTMLInputElement;
            if (target.files && target.files[0]) {
                const file = target.files[0];
                if (file.name.endsWith(".csv")) {
                    uploadFile(file);
                } else {
                    setIsFileError(true);
                    setTimeout(() => {
                        setIsFileError(false);
                    }, 2000)
                }
            }
        };
        input.click();
    };

    const uploadFile = (file: File) => {
        const formData = new FormData();
        formData.append('csvFile', file);
    
        axios.post('https://api.tasktrack.teracock.com/tasks', formData)
            .then(response => {
                console.log('HERE');
                
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000)
            })
            .catch(error => {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000)
            });
    };

    const handleGetTasks= async () => {
        axios.get('https://api.tasktrack.teracock.com/tasks')
        .then(response => {
            setListData(response.data);
            setIsOpenList(true);
        })
        .catch(error => {
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000)
        });
    };

    useOnClickOutside(listRef, () => {
        setIsOpenList(false);
      });

    return(
        <Background>
            {(isError || isFileError || isSuccess) && 
            <WrapperText>
                {isError && <ErrorText>Something went wrong</ErrorText>}
                {isFileError && <ErrorText>You can add only csv files</ErrorText>}
                {isSuccess && <SuccessText>File was successfully added</SuccessText>}
            </WrapperText>}
            <Container>
                <ButtonCreate onClick={handleFileUpload}>Create</ButtonCreate>
                <ButtonShow onClick={handleGetTasks}>Show</ButtonShow>
            </Container>
            
            {isOpenList && listData && 
            <ListWrapper ref={listRef}>
                {listData.map((item: any) => {
                    return (
                        <Link key={item.id} style={{textDecoration: 'none', color: 'inherit'}} to={`/task/${item.id}`}>
                            <ListElemWrapper>
                                <img style={{width: '25px', height: '25px'}} src={TaskSvg} alt="Task Icon" />
                                <ListElemText>{item.name}</ListElemText>
                            </ListElemWrapper>
                        </Link>
                    )
                })}
            </ListWrapper>
            }
        </Background>
    )
}

export default Main;