import { Route, Routes } from 'react-router';
import {JSX} from 'react';
import Main from '../../Pages/Main';
import Task from '../../Pages/Task';

interface IRoutes {
  path: string,
  component: JSX.Element
}

const ContentProvider = () => {
  const ROUTES: IRoutes[] = [
    {
      path: '/',
      component: <Main/>
    },
    {
      path: '/task/:taskId',
      component: <Task/>
    }
  ];

  return (
    <Routes>
        {ROUTES.map(({path, component}) => <Route key={path} path={path} element={component}/>)}
    </Routes>
  );
};

export default ContentProvider;