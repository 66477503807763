import styled from 'styled-components';
import background from '../../images/background.jpg'

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
`
export const Container = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
`
export const TaskColumn = styled.p`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 12px;
    color: black;
    overflow-wrap: break-word;
    margin: 0;
    max-width: 70px;
` 

export const TaskText = styled.p`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 12px;
    color: black;
    overflow-wrap: break-word;
    margin: 0;
    max-width: 70px;
` 
export const CopyButton = styled.button`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 12px;
    bacgkround-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-color: black;
`