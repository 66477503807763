import styled from 'styled-components';
import background from '../../images/background.jpg'

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
`

export const WrapperText = styled.div`
    position: absolute;
    height: 10%;
    width: 40%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
`

export const ErrorText = styled.p`
    color: red;
    text-align: center;
`
export const SuccessText = styled.p`
    color: green;
    text-align: center;
`

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 20px;
    height: 40%;
    background-color: #C5FAD3;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 15px;
` 

export const ButtonCreate = styled.button`
    width: 200px;
    height: 60px;
    font-family: 'Roboto Mono', monospace;
    background-color: #4871CF;
    font-weight: 500;
    font-size: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    color: white;
    cursor: pointer;
    border: none;
`
export const ButtonShow = styled.button`
    width: 200px;
    height: 60px;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    font-size: 20px;
    background-color: white;
    border-radius: 4px;
    color: #4871CF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border: none;
`

export const ListWrapper = styled.div`
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%);
    overflow-y: scroll;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    max-height: 200px;
    min-height: 100px;
    max-width: 400px;
    width: 60%;

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`

export const ListElemWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: #8dafdc;
    cursor: pointer;

    gap: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
`

export const ListElemText = styled.p`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 12px;
    overflow-wrap: break-word;
`